import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Product Types">
      <div className="headline">
        <h1 className="text-primary m-0">Product Types</h1>
      </div>
      <p>Our product type at a glance</p>

      <h2 className="h3">Reserved Keywords</h2>
      <p>
        Application should not use the keywords as defined at below, to avoid
        conflict.
      </p>

      <table className="table table-hover specification">
        <thead>
          <tr>
            <td width="15%">Product Type</td>
            <td width="15%">Short Name</td>
            <td width="70%">Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="reference">SP</span>
            </td>
            <td>Scan &amp; Pay</td>
            <td>
              The most simplest yet powerful, simply scan and make payment.
            </td>
          </tr>
          <tr>
            <td>
              <span className="reference">PAC</span>
            </td>
            <td>Pay at Counter</td>
            <td>
              Liquid Pay Pay@Counter helps to automate the payment process. It
              is a cost-effective and productive feature that allows consumers
              to make payments at the counter.
            </td>
          </tr>
          <tr>
            <td>
              <span className="reference">PAT</span>
            </td>
            <td>Pay at Table</td>
            <td>Pay@Table makes ordering, closing and paying the bill easy.</td>
          </tr>
        </tbody>
      </table>

      <p className="text-muted">
        There are more product type coming, please check again later.
      </p>
    </DocumentLayout>
  )
}
